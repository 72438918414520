var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle ? _c('b-row', {
    staticClass: "content-header m-0 flex-grow-1 d-none d-lg-flex"
  }, [_c('b-col', {
    staticClass: "content-header-left",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('b-row', {
    staticClass: "breadcrumbs-top"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "breadcrumb-wrapper"
  }, [_c('b-breadcrumb', [_c('b-breadcrumb-item', {
    attrs: {
      "to": "/"
    }
  }, [_c('feather-icon', {
    staticClass: "align-text-top",
    attrs: {
      "icon": "HomeIcon",
      "size": "16"
    }
  })], 1), _vm._l(_vm.$store.state.breadcrumbs.breadcrumbs, function (item, index) {
    return _c('b-breadcrumb-item', {
      key: index,
      staticClass: "justify-content-center",
      attrs: {
        "active": item === null || item === void 0 ? void 0 : item.active,
        "to": item === null || item === void 0 ? void 0 : item.to
      }
    }, [item !== null && item !== void 0 && item.dynamic && item !== null && item !== void 0 && item.dynamic ? _c('b-spinner', {
      attrs: {
        "label": "Loading...",
        "small": ""
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(item.text) + " ")])], 1);
  })], 2)], 1)])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }