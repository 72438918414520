<template>
  <div>
    <b-spinner
      v-if="meLoading"
      label="Loading..."
    />

    <b-nav-item-dropdown
      v-if="me && !meLoading"
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ me?.name }}
          </p>
        </div>
        <b-avatar
          size="40"
          :src="me?.avatar_image"
          :badge="me?.team_invites?.length > 0 || me?.friend_invites?.length > 0"
          badge-variant="warning"
          class="badge-minimal"
          variant="light-primary"
        >
          <feather-icon
            v-if="!me?.name"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <b-dropdown-text
        v-if="me?.id"
        link-class="d-flex align-items-center"
      >
        <b-row class="justify-content-between flex-wrap">
          <b-col
            class="font-small-4 d-flex justify-content-start"
          >
            Balance:
          </b-col>

          <b-col
            class="font-small-4 d-flex justify-content-end"
            style="color: #27AE60"
          >
            {{ me?.balance || 0 }} €
          </b-col>
        </b-row>
      </b-dropdown-text>

      <b-dropdown-divider />

      <b-dropdown-item
        v-if="me?.id"
        :to="{ name: 'player-profile', params: { id: me?.id}}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Profile</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-if="me?.team_invites?.length > 0"
        :to="{ name: 'account-settings', params: { tab:'teams' }}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserPlusIcon"
          class="mr-50"
        />
        <span class="mr-1">Team invites</span>
        <feather-icon
          icon="InfoIcon"
          class="ml-auto text-warning"
          size="10"
        />
      </b-dropdown-item>

      <b-dropdown-item
        v-if="me?.friend_invites?.length > 0"
        :to="{ name: 'account-settings', params: { tab:'friends' }}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserPlusIcon"
          class="mr-50"
        />
        <span class="mr-1">Friend invites</span>
        <feather-icon
          icon="InfoIcon"
          class="ml-auto text-warning"
          size="10"
        />
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-item
        v-if="$route.matched.some(({ path }) => path.startsWith('/dashboard'))"
        link-class="d-flex align-items-center"
        @click="exitOrganizer"
      >
        <feather-icon
          size="16"
          icon="RepeatIcon"
          class="mr-50"
        />
        <span>Exit organizer</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-if="allowOrganizerModeAccess() && !$route.matched.some(({ path }) => path.startsWith('/dashboard'))"
        link-class="d-flex align-items-center"
        @click="enterOrganizerMode"
      >
        <feather-icon
          size="16"
          icon="RepeatIcon"
          class="mr-50"
        />
        <span>Organizer mode</span>
        <select-organizer-modal
          v-if="me?.organizers?.length > 1"
          :organizer-list="me?.organizers"
        />
      </b-dropdown-item>

      <b-dropdown-divider v-if="allowOrganizerModeAccess()" />

      <b-dropdown-item
        :to="{ name: 'account-settings', params: {tab: 'settings'} }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BAvatar,
  BCol,
  BDropdownDivider,
  BDropdownItem,
  BDropdownText,
  BNavItemDropdown,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import SelectOrganizerModal from '@/views/common/SelectOrganizerModal.vue'
import { allowOrganizerModeAccess } from '@/views/player/playerUtils'

export default {
  components: {
    BSpinner,
    SelectOrganizerModal,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,
    BCol,
    BDropdownText,
  },
  data() {
    return {
      avatarText,
      avatarKey: 0,

    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
    meLoading() {
      return this.$store.getters['user/isLoading']
    },
  },
  methods: {
    allowOrganizerModeAccess,
    async logout() {
      window.$cookies.remove('access_token')

      await this.$store.dispatch('user/resetMe')

      window.location.href = '/home'
    },
    exitOrganizer() {
      this.$router.push({ name: 'home' })
    },
    enterOrganizerMode() {
      if (this.me?.organizers?.length === 1) {
        this.$router.push({
          name: 'dashboard',
          params: { id: this.me?.organizers[0]?.id },
        })

        return
      }

      this.$bvModal.show('select-organizer-modal')
    },
  },
}
</script>
