var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "menu-class": "dropdown-menu-media",
      "lazy": "",
      "right": ""
    },
    on: {
      "toggle": _vm.onToggle
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": _vm.unreadMessages.length > 0 ? _vm.unreadMessages.length : null,
            "badge-classes": "bg-danger",
            "icon": "BellIcon",
            "size": "21"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('li', {
    staticClass: "dropdown-menu-header",
    style: {
      borderBottom: _vm.notifications.length > 0 && _vm.loading === false ? '1px solid #e0e0e0' : 'none'
    }
  }, [_c('div', {
    staticClass: "dropdown-header d-flex"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_vm._v(" Notifications ")]), _vm.unreadMessages.length > 0 ? _c('div', [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.unreadMessages.length) + " New ")])], 1) : _vm._e()]), _vm.notifications.length === 0 && _vm.loading === false ? _c('div', {
    staticClass: "font-small-4 text-muted",
    style: {
      paddingBottom: '1.22rem',
      paddingLeft: ' 1.28rem'
    }
  }, [_vm._v(" No notifications ")]) : _vm._e(), _vm.loading === true ? _c('div', {
    style: {
      paddingBottom: '1.22rem',
      paddingLeft: ' 1.28rem'
    }
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e()]), _vm.notifications.length > 0 && _vm.loading === false ? _vm._m(0) : _vm._e(), _vm.unreadMessages.length > 0 && _vm.loading === false ? _c('li', {
    staticClass: "dropdown-menu-footer"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.onReadAll
    }
  }, [_vm._v("Read all notifications ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "li"
    }
  }, _vm._l(_vm.notifications, function (notification) {
    var _JSON$parse;
    return _c('b-link', {
      key: notification.id,
      on: {
        "click": function click($event) {
          return _vm.onNotificationClick(notification);
        }
      }
    }, [_c('b-media', {
      staticClass: "align-items-center",
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [notification.avatar ? _c('b-avatar', {
            attrs: {
              "size": "32",
              "src": notification.avatar,
              "text": notification.avatar,
              "variant": notification.type
            }
          }) : _c('b-avatar', {
            attrs: {
              "size": "32",
              "variant": notification.read === false ? 'light-primary' : 'light-secondary'
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "MessageSquareIcon"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s((_JSON$parse = JSON.parse(notification.data)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.message) + " ")])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification.subtitle))])])], 1);
  }), 1);
}]

export { render, staticRenderFns }