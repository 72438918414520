<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header m-0 flex-grow-1 d-none d-lg-flex"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="(item, index) in $store.state.breadcrumbs.breadcrumbs"
                :key="index"
                :active="item?.active"
                :to="item?.to"
                class="justify-content-center"
              >
                <b-spinner
                  v-if="item?.dynamic && item?.dynamic"
                  label="Loading..."
                  small
                />
                <span>
                  {{ item.text }}
                </span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BSpinner,
  },
  mixins: [breadcrumbManager],
  data() {
    return {}
  },
  watch: {
    $route() {
      this.generateBreadcrumbs()
    },
  },
  mounted() {
    this.generateBreadcrumbs()
  },
  methods: {
    generateBreadcrumbs() {
      this.emptyBreadcrumbs()

      if (this.$route.meta.breadcrumb) {
        this.$route.meta.breadcrumb.forEach(item => {
          this.pushBreadcrumb(item)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-header-left {
  padding-left: 0;
}
</style>
