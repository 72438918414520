<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    lazy
    right
    @toggle="onToggle"
  >
    <template #button-content>
      <feather-icon
        :badge="unreadMessages.length > 0 ? unreadMessages.length : null"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li
      class="dropdown-menu-header"
      :style="{ borderBottom: notifications.length > 0 && loading === false ? '1px solid #e0e0e0' : 'none' }"
    >
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>

        <div v-if="unreadMessages.length > 0">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ unreadMessages.length }} New
          </b-badge>
        </div>
      </div>

      <div
        v-if="notifications.length === 0 && loading === false"
        class="font-small-4 text-muted"
        :style="{paddingBottom: '1.22rem', paddingLeft: ' 1.28rem'}"
      >
        No notifications
      </div>

      <div
        v-if="loading === true"
        :style="{paddingBottom: '1.22rem', paddingLeft: ' 1.28rem'}"
      >
        <b-spinner
          label="Loading..."
        />
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notifications.length > 0 && loading === false"
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
        @click="onNotificationClick(notification)"
      >

        <b-media class="align-items-center">
          <template #aside>
            <b-avatar
              v-if="notification.avatar"
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />

            <b-avatar
              v-else
              size="32"
              :variant="notification.read === false ? 'light-primary' : 'light-secondary'"
            >
              <feather-icon icon="MessageSquareIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ JSON.parse(notification.data)?.message }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <li
      v-if="unreadMessages.length > 0 && loading === false"
      class="dropdown-menu-footer"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="onReadAll"
      >Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BLink, BMedia, BNavItemDropdown, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import EventBus from '@/common/event-bus'

export default {
  components: {
    BSpinner,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    loading: false,
    notifications: [],
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    },
  }),
  computed: {
    unreadMessages() {
      return this.notifications.filter(notification => notification.read === false) || []
    },
  },
  watch: {

  },
  async mounted() {
    EventBus.$on('notification-received', this.handleEvent)

    await this.fetchNotifications()
  },
  methods: {
    async onNotificationClick(notification) {
      const { success } = await this.$api.notifications.markNotificationAsRead(Number(notification.id))

      if (success) {
        this.notifications = this.notifications.map(item => {
          if (item.id === notification.id) {
            item.read = true
          }

          return item
        })
      }
    },
    async onReadAll() {
      const { success } = await this.$api.notifications.markNotificationAsRead(null)

      if (success) {
        this.notifications = this.notifications.map(item => {
          item.read = true

          return item
        })
      }
    },
    async fetchNotifications() {
      this.loading = true

      const { data } = await this.$api.notifications.fetchNotifications()

      this.loading = false

      this.notifications = data
    },
    async handleEvent() {
      this.notifications.push({
        id: Math.random(),
        read: false,
      })
    },
    async onToggle() {
      await this.fetchNotifications()
    },
  },
  unmounted() {
    EventBus.$off('notification-received', this.handleEvent)
  },
}
</script>

<style>

</style>
