const navMenuItems = [
  {
    title: 'Tournaments',
    route: 'home',
    icon: 'GridIcon',
    children: [
      {
        title: 'eSports',
        alterStyle: 'justify-content-end header-group',
        children: [
          {
            title: 'Counter Strike 2',
            image: 'images/games/cs2.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'counter-strike-2' },
            },
          },
          {
            title: 'Fortnite',
            image: 'images/games/fortnite.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'fortnite' },
            },
          },
          {
            title: 'Valorant',
            image: 'images/games/valorant.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'valorant' },
            },
          },
          {
            title: 'League of Legends',
            image: 'images/games/lol.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'lol' },
            },
          },
          {
            title: 'Call of Duty',
            image: 'images/games/cod.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'call-of-duty' },
            },
          },
          {
            title: 'Call of Duty UO',
            image: 'images/games/cod_uo.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'call-of-duty-uo' },
            },
          },
          {
            title: 'Call of Duty 2',
            image: 'images/games/cod2.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'call-of-duty-2' },
            },
          },
          {
            title: 'Asetto Corsa Competizione',
            image: 'images/games/asseto.png',
            route: {
              name: 'tournaments-search',
              params: { game: 'asetto-corsa-competizione' },
            },
          },
        ],
      },
    ],
  },
]

const navMenuFooterItems = [
  {
    header: 'Quick links',
  },
  {
    title: 'About us',
    href: 'https://about.360clash.com/',
    icon: 'CoffeeIcon',
  },
  {
    title: 'Terms',
    href: 'https://about.360clash.com/terms-and-conditions/',
    icon: 'CheckSquareIcon',
  },
]

export { navMenuItems, navMenuFooterItems }
