<template>
  <p class="clearfix mb-0">

    <span class="float-md-right d-none d-md-block">Crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>

export default {
  components: {
  },
}
</script>
