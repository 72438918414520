import { ref, watch } from '@vue/composition-api'
import * as searchService from '@/api/search/searchService'

export default function useAutoSuggest(props) {
  const filteredData = ref({})

  /*
                                              players | teams | tournaments
                                               */

  /**
   * Filter group against provided query
   * Grp Structure:
   * {
   *    key: 'title',
   *    data: [
   *      title: 'Admin', img: 'someImage.png',
   *      title: 'Template', img: 'otherImage.png',
   *    ]
   * }
   * @param {Object} grp Group object to perform filter on
   * @param {String} query Query string to filter
   */
  // eslint-disable-next-line no-unused-vars
  const filterGrp = (grp, query) => {
    const exactEle = grp.data.filter(item => item[grp.key].toLowerCase().startsWith(query.toLowerCase()))
    const containEle = grp.data.filter(
      // prettier-ignore
      item => !item[grp.key].toLowerCase().startsWith(query.toLowerCase()) && item[grp.key].toLowerCase().indexOf(query.toLowerCase()) > -1,
    )
    return exactEle.concat(containEle).slice(0, props.searchLimit)
  }

  const searchQuery = ref('')
  const resetsearchQuery = () => {
    searchQuery.value = ''
  }

  const handleSearchQueryUpdate = async val => {
    if (val === '') {
      filteredData.value = {}
    } else {
      const queriedData = {}
      // const dataGrps = Object.keys(props.data)
      const dataGrps = ['users', 'teams', 'organizers', 'tournaments']

      const { data } = await searchService.globalSearch(
        ['users', 'teams', 'organizers', 'tournaments'],
        val,
      )

      dataGrps.forEach((grp, i) => {
        // queriedData[dataGrps[i]] = filterGrp(props.data[grp], val)
        if (grp === 'users') {
          queriedData[dataGrps[i]] = data?.filter(item => item.type === 'user')
        } else if (grp === 'teams') {
          queriedData[dataGrps[i]] = data?.filter(item => item.type === 'team')
        } else if (grp === 'organizers') {
          queriedData[dataGrps[i]] = data?.filter(item => item.type === 'organizer')
        } else if (grp === 'tournaments') {
          queriedData[dataGrps[i]] = data?.filter(
            item => item.type === 'tournament',
          )
        }
      })

      filteredData.value = queriedData
    }
  }

  watch(searchQuery, val => handleSearchQueryUpdate(val))

  return {
    searchQuery,
    resetsearchQuery,
    filteredData,
  }
}
