var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "select-organizer-modal",
      "title": "Choose organizer",
      "no-close-on-backdrop": "",
      "size": "sm"
    },
    on: {
      "hidden": _vm.handleHide
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])];
      }
    }])
  }, [_c('b-list-group', _vm._l(_vm.organizerList, function (organizer) {
    return _c('b-list-group-item', {
      key: organizer.id,
      staticClass: "d-flex align-items-center",
      on: {
        "click": function click() {
          return _vm.handleSelectClick(organizer.id);
        }
      }
    }, [_c('b-avatar', {
      attrs: {
        "src": organizer.avatar_image,
        "variant": "light-primary",
        "size": "38px"
      }
    }), _c('h5', {
      staticClass: "ml-1",
      staticStyle: {
        "display": "inline"
      }
    }, [_vm._v(" " + _vm._s(organizer.name) + " ")])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }